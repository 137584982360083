<template>
  <div class="col-12;" style="margin-bottom: 1em">

  </div>
  <Message severity="success"  v-if="quote.status === 'ACCEPTED'">
    Zaakceptowano zlecenie, potwierdź realizacje.
  </Message>
  <div class="grid">

    <Dialog v-model:visible="productDialog" :style="{width: '450px'}" header="Nowa oferta" :modal="true"
            class="p-fluid">
      <div class="formgrid grid">
        <div class="field col">
          <label for="price">Cena netto</label>
          <InputNumber id="price" v-model="offer.netPrice" mode="currency" currency="PLN" locale="pl" autofocus
                       :class="{'p-invalid': submitted && !offer.realizationDays}"/>
          <small class="p-invalid" v-if="submitted && !offer.netPrice">Wymagane</small>
        </div>
      </div>
      <div class="field">
        <label for="name">Czas realizacji (dni kalendarzowe)</label>
        <InputText id="name" v-model.trim="offer.realizationDays" required="true"
                   :class="{'p-invalid': submitted && !offer.realizationDays}" placeholder="1-2 dni"/>
        <small class="p-invalid" v-if="submitted && !offer.realizationDays">Wymagane</small>
      </div>
      <div class="field">
        <label for="description">Notatka</label>
        <Textarea id="description" v-model="offer.note" required="true" rows="3" cols="20"/>
      </div>
      <div class="field">
        <label for="inventoryStatus" class="mb-3">Waluta</label>
        <Dropdown id="inventoryStatus" v-model="offer.currency" :options="statuses" optionLabel="label"
                  option-value="value"
                  placeholder="Waluta" disabled>
          <template #value="slotProps">
            <div v-if="slotProps.value && slotProps.value.value">
              <span :class="'product-badge status-' +slotProps.value.value">{{ slotProps.value.label }}</span>
            </div>
            <div v-else-if="slotProps.value && !slotProps.value.value">
              <span :class="'product-badge status-' +slotProps.value.toLowerCase()">{{ slotProps.value }}</span>
            </div>
            <span v-else>
									{{ slotProps.placeholder }}
								</span>
          </template>
        </Dropdown>
      </div>


      <template #footer>
        <Button label="Anuluj" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
        <Button label="Zapisz" icon="pi pi-check" class="p-button-text" @click="saveProduct"/>
      </template>
    </Dialog>


    <div class="col-3">
      <Card style="min-height: 18em;">
        <template #title>Dane</template>
        <template #content>
          <h3>Zlecenie nr {{ quote.id }}</h3>
          <div>{{ quote.created }}</div>
          <div>{{ quote.sourceLang }} -> {{ quote.destinationLang }}</div>
          <div v-if="quote.sworn">Przysięgłe</div>
          <div>{{ quote.status }}</div>
          <Button  v-if="quote.status === 'ACCEPTED'" label="Potwierdzam" icon="pi pi-check" class="p-button-success mr-2" @click="confirm"/>
        </template>
      </Card>
    </div>
    <div class="col-9">
      <Card style="min-height: 18em;">
        <template #title>Załączniki</template>
        <template #content>
          <DataTable :value="quote.attachments" responsiveLayout="scroll">
            <Column field="fileName" headerStyle="min-width:10rem;">
              <template #body="slotProps">
                <a :href="slotProps.data.url" v-text="slotProps.data.fileName" target="_blank" />
              </template>
            </Column>
          </DataTable>
        </template>
      </Card>
    </div>

    <div class="col-12">

      <Card style="">
        <template #header>

        </template>
        <template #title>Oferty</template>
        <template #subtitle></template>
        <template #content>
          <DataTable :value="quote.offers" responsiveLayout="scroll">
            <Column field="status" header="Status"></Column>
            <Column field="netPrice" header="Cena netto"></Column>
            <Column field="realizationDays" header="Czas realizacji"></Column>
            <Column field="note" header="Notatka"></Column>
            <Column field="action" headerStyle="min-width:10rem;">
              <template #body="slotProps">
                <Button icon="pi pi-trash" class="p-button-rounded  p-button-secondary"
                        @click="confirmDeleteProduct(slotProps.data)" style="margin-left: 0.5em"/>
              </template>
            </Column>
          </DataTable>
        </template>
        <template #footer>
          <Button label="Dodaj" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew"/>

        </template>
      </Card>
    </div>


  </div>
</template>

<script>
import {useRoute} from "vue-router";
import UserService from "@/services/user.service";

export default {
  mounted() {
    let route = useRoute();
    this.offerId = route.params.offerId;

    UserService.getOffer(this.offerId)
        .then((resp) => {
              this.quote = resp.data
            },
            (error) => {
              console.log("Failed to get offerId" + this.offerId, error)
            }
        )

  },
  methods: {
    confirmDeleteProduct(obj) {
      console.log(obj.id)
      UserService.deleteOffer(this.offerId, obj.id)
          .then((response) => {
            this.$toast.add({severity: 'success', summary: 'Sukces', detail: 'Usunięto', life: 3000});
            this.productDialog = false;
            this.offer = {};
            this.quote = response.data
          }, (error) => {
            console.error(error)
            this.$toast.add({severity: 'error', summary: 'Successful', detail: 'Błąd', life: 3000});
          })
    },
    confirm() {
      UserService.confirmOffer(this.offerId)
          .then((response) => {
            this.$toast.add({severity: 'success', summary: 'Sukces', detail: 'Potwierdzono', life: 3000});
            this.quote = response.data
          }, (error) => {
            console.error(error)
            this.$toast.add({severity: 'error', summary: 'Sukces', detail: 'Błąd', life: 3000});
          })
    },
    openNew() {
      this.offer = {
        currency: 'PLN',
      };
      this.submitted = false;
      this.productDialog = true;
    },
    hideDialog() {
      this.productDialog = false;
      this.submitted = false;
    },
    saveProduct() {
      this.submitted = true;
      if (this.offer.netPrice) {
        UserService.saveOffer(this.offerId, this.offer)
            .then((response) => {
              this.$toast.add({severity: 'success', summary: 'Successful', detail: 'Product Updated', life: 3000});
              this.productDialog = false;
              this.offer = {};
              this.quote = response.data
            }, (error) => {
              console.error(error)
              this.$toast.add({severity: 'error', summary: 'Successful', detail: 'Błąd', life: 3000});
            })
        //todo then


      }
    },
  },
  data() {
    return {
      offerId: '',
      productDialog: false,
      submitted: false,
      offer: {},
      dropdownItems: [
        {name: 'Option 1', code: 'Option 1'},
        {name: 'Option 2', code: 'Option 2'},
        {name: 'Option 3', code: 'Option 3'}
      ],
      offers: [{
        "number": "#1",
        "price": "90 zł",
        "vat": "0%",
        "days": "3 dni",
        "payment": "Przelewy24"
      }, {
        "number": "#2",
        "price": "150 zł",
        "vat": "0%",
        "days": "1 dni",
        "payment": "-"
      },
      ],
      dropdownItem: null,
      quote: {
        id: "",
        created: "",
        sourceLang: "",
        destinationLang: "",
        sworn: false
      },
      statuses: [
        {label: 'PLN', value: 'PLN'},
      ],
      client: {
        "name": "Marek Nowak",
        "email": "mareknowak@gmail.com",
        "phone": "7391230123",
      },


    }
  }
}

</script>
